<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="relative bg-white overflow-hidden">

    <NawigacjaGlowna></NawigacjaGlowna>

    <main>

      <div class="bg-indigo-600">
        <div class="pt-12 sm:pt-16 lg:pt-24">
          <div class="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
            <div class="max-w-3xl mx-auto space-y-2 lg:max-w-none">
              <h2 class="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
                Oferta
              </h2>
              <p class="text-3xl font-extrabold text-white sm:text-4xl lg:text-5xl">
                Geobox OBU e-TOLL
              </p>
              <p class="text-xl text-gray-300">
                Geobox OBU to certyfikowane przez KAS urządzenie służące do automatycznego poboru opłat za przejazd po wybranych odcinkach autostrad, dróg ekspresowych i krajowych zarządzanych przez GDDKiA.
              </p>
            </div>
          </div>
        </div>
        <div class="mt-8 pb-12 bg-gray-50 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
          <div class="relative">
            <div class="absolute inset-0 h-3/4 bg-indigo-600" />
            <div class="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <div class="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5 lg:space-y-0">
                <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div class="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                    <div>
                      <h3 class="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600" id="tier-obu">
                        Geobox OBU
                      </h3>
                    </div>
                    <div class="mt-4 flex items-baseline text-6xl font-extrabold">
                      100 zł
                      <span class="ml-1 text-2xl font-medium text-gray-500">
                      netto/rok
                    </span>
                    </div>
                    <p class="mt-5 text-lg text-gray-500">
                      Podstawowa wersja aplikacji Geobox
                    </p>
                  </div>
                  <div class="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
                    <ul class="space-y-4">
                      <li class="flex items-start">
                        <div class="flex-shrink-0">
                          <CheckIcon class="h-6 w-6 text-green-500" aria-hidden="true" />
                        </div>
                        <p class="ml-3 text-base text-gray-700">
                          Transmisja danych
                        </p>
                      </li>
                      <li class="flex items-start">
                        <div class="flex-shrink-0">
                          <CheckIcon class="h-6 w-6 text-green-500" aria-hidden="true" />
                        </div>
                        <p class="ml-3 text-base text-gray-700">
                          Dostęp do panelu diagnostycznego urządzeń
                        </p>
                      </li>
                      <li class="flex items-start">
                        <div class="flex-shrink-0">
                          <CheckIcon class="h-6 w-6 text-green-500" aria-hidden="true" />
                        </div>
                        <p class="ml-3 text-base text-gray-700">
                          Alerty
                        </p>
                      </li>
                    </ul>
                    <div class="rounded-md shadow">
                      <a href="mailto:sprzedaz@geobox.app?subject=Zamówienie [GEOBOX OBU]" class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600" aria-describedby="tier-standard">
                        Złóż zamówienie
                      </a>
                    </div>
                  </div>
                </div>
                <div class="flex flex-col rounded-lg shadow-lg overflow-hidden">
                  <div class="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                    <div>
                      <h3 class="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600" id="tier-obu-plus">
                        Geobox OBU Plus
                      </h3>
                    </div>
                    <div class="mt-4 flex items-baseline text-6xl font-extrabold">
                      150 zł
                      <span class="ml-1 text-2xl font-medium text-gray-500">
                      netto/rok
                    </span>
                    </div>
                    <p class="mt-5 text-lg text-gray-500">
                      Rozszerzona wersja aplikacji Geobox
                    </p>
                  </div>
                  <div class="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
                    <ul class="space-y-4">
                      <li class="flex items-start">
                        <div class="flex-shrink-0">
                          <CheckIcon class="h-6 w-6 text-green-500" aria-hidden="true" />
                        </div>
                        <p class="ml-3 text-base text-gray-700">
                          Transmisja danych
                        </p>
                      </li>
                      <li class="flex items-start">
                        <div class="flex-shrink-0">
                          <CheckIcon class="h-6 w-6 text-green-500" aria-hidden="true" />
                        </div>
                        <p class="ml-3 text-base text-gray-700">
                          Dostęp do panelu diagnostycznego urządzeń
                        </p>
                      </li>
                      <li class="flex items-start">
                        <div class="flex-shrink-0">
                          <CheckIcon class="h-6 w-6 text-green-500" aria-hidden="true" />
                        </div>
                        <p class="ml-3 text-base text-gray-700">
                          Alerty
                        </p>
                      </li>

                      <li class="flex items-start">
                        <div class="flex-shrink-0">
                          <CheckIcon class="h-6 w-6 text-green-500" aria-hidden="true" />
                        </div>
                        <p class="ml-3 text-base text-gray-700">
                          Lokalizacja pojazdu na mapie
                        </p>
                      </li>
                    </ul>
                    <div class="rounded-md shadow">
                      <a href="mailto:sprzedaz@geobox.app?subject=Zamówienie [GEOBOX OBU PLUS]" class="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600" aria-describedby="tier-standard">
                        Złóż zamówienie
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4 relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:mt-5">
            <div class="max-w-md mx-auto lg:max-w-5xl">
              <div class="rounded-lg bg-gray-100 px-6 py-8 sm:p-10 lg:flex lg:items-center">
                <div class="flex-1">
                  <div>
                    <h3 class="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-white text-gray-800">
                      Urządzenie Geobox OBU
                    </h3>
                  </div>
                  <div class="mt-4 text-lg text-gray-600">Świadczenie usług wymaga zakupu urządzenia Geobox OBU w cenie <span class="font-semibold text-gray-900">290 zł netto/szt</span>.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bg-white py-16 sm:py-24">
        <div class="relative sm:py-16">
          <div aria-hidden="true" class="hidden sm:block">
            <div class="absolute inset-y-0 left-0 w-1/2 bg-gray-50 rounded-r-3xl"></div>
            <svg class="absolute top-8 left-1/2 -ml-3" width="404" height="392" fill="none" viewBox="0 0 404 392">
              <defs>
                <pattern id="8228f071-bcee-4ec8-905a-2a059a2cc4fb" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                  <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width="404" height="392" fill="url(#8228f071-bcee-4ec8-905a-2a059a2cc4fb)" />
            </svg>
          </div>
          <div class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
            <div class="relative rounded-2xl px-6 py-10 bg-indigo-600 overflow-hidden shadow-xl sm:px-12 sm:py-20">
              <div aria-hidden="true" class="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
                <svg class="absolute inset-0 h-full w-full" preserveAspectRatio="xMidYMid slice" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1463 360">
                  <path class="text-indigo-500 text-opacity-40" fill="currentColor" d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z" />
                  <path class="text-indigo-700 text-opacity-40" fill="currentColor" d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z" />
                </svg>
              </div>
              <div class="relative">
                <div class="sm:text-center">
                  <h2 class="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                    Zapisz się na do newslettera
                  </h2>
                  <p class="mt-6 mx-auto max-w-2xl text-lg text-indigo-200">
                    Zapisz się już dziś. Otrzymuj powiadomienia o naszych nowościach i promocjach.
                  </p>
                </div>
                <form method="post" action="" @submit.prevent="submitForm" class="mt-12 sm:mx-auto sm:max-w-lg sm:flex">
                  <div class="min-w-0 flex-1">
                    <label for="email" class="sr-only">Adres email</label>
                    <input id="email" type="email" name="email" v-model="email" class="block w-full border border-transparent rounded-md px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600" placeholder="Wprowadź adres email">
                  </div>
                  <div class="mt-4 sm:mt-0 sm:ml-3">
                    <button type="submit" class="block w-full rounded-md border border-transparent px-5 py-3 bg-indigo-500 text-base font-medium text-white shadow hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:px-10">Zapisz się</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    </main>

    <div v-show="showModal" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

        <!--
          Background overlay, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0"
            To: "opacity-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100"
            To: "opacity-0"
        -->
        <transition
            enter-active-class="transition ease-out duration-300 transform"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition ease-in duration-200 transform"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
          <div v-show="showModal" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        </transition>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        <!--
          Modal panel, show/hide based on modal state.

          Entering: "ease-out duration-300"
            From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            To: "opacity-100 translate-y-0 sm:scale-100"
          Leaving: "ease-in duration-200"
            From: "opacity-100 translate-y-0 sm:scale-100"
            To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        -->
        <transition
            enter-active-class="transition ease-out duration-300 transform"
            enter-from-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to-class="opacity-100 translate-y-0 sm:scale-100"
            leave-active-class="transition ease-in duration-200"
            leave-from-class="opacity-100 translate-y-0 sm:scale-100"
            leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div v-show="true" class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
            <div>
              <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                <!-- Heroicon name: outline/check -->
                <svg class="h-6 w-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                </svg>
              </div>
              <div class="mt-3 text-center sm:mt-5">
                <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                  Dziękujemy!
                </h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    Proszę potwierdzić zgłoszenie klikając w link otrzymanej wiadomości email.
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-5 sm:mt-6">
              <button @click="showModal = false" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm">
                Zamknij
              </button>
            </div>
          </div>
        </transition>

      </div>
    </div>
    <div v-show="showBanner" class="fixed bottom-0 inset-x-0 pb-2 sm:pb-5">
      <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div class="p-2 rounded-lg bg-indigo-600 shadow-lg sm:p-3">
          <div class="flex items-center justify-between flex-wrap">
            <div class="w-0 flex-1 flex items-center">
              <span class="flex p-2 rounded-lg bg-indigo-800">
                <!-- Heroicon name: outline/speakerphone -->
                <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z" />
                </svg>
              </span>
              <p class="ml-3 font-medium text-white truncate">
                <span class="md:hidden">
                  {{ infoShort }}
                </span>
                <span class="hidden md:inline">
                  {{ info }}
                </span>
              </p>
            </div>
            <div class="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
              <button type="button" @click="showBanner = false" class="-mr-1 flex p-2 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white">
                <span class="sr-only">Dismiss</span>
                <!-- Heroicon name: outline/x -->
                <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>

import {CheckIcon} from '@heroicons/vue/solid';
import axios from "axios"
import NawigacjaGlowna from "@/components/NawigacjaGlowna";

export default {
  name: 'Oferta',
  components: {
    CheckIcon,
    NawigacjaGlowna
  },
  data: () => ({
    isOpen: false,
    showModal: false,
    showBanner: false,
    disableButton : true,
    email: "",
    info: "",
    infoShort: ""
  }),
  methods: {
    async submitForm() {
      const response = await axios.post("https://6gda35s92m.execute-api.eu-west-1.amazonaws.com/prod/kontakt", {"email": this.email});
      if (response.status === 200){
        if (response.data.statusCode === 202){
          this.showModal = true;
        } else {
          this.showBanner = true;
          if (response.data.statusCode === 400 && response.data.body.code === 1000) {
            this.info = "Sprawdź proszę poprawność adresu email."
            this.infoShort = "Niepoprawny email."
          } else if (response.data.statusCode === 409 && response.data.body.code === 1008) {
            this.info = "Podany adres email został już zarejestrowany."
            this.infoShort = "Adres email został już zarejestrowany."
          }
        }
      } else {
        this.showBanner = true;
        this.info = "Wystąpił nieoczekiwany błąs. Spróbuj później."
        this.infoShort = "Wystapił błąd."
      }
    }
  }
}
</script>
