<template>
  <div class="relative py-16 bg-white overflow-hidden">
    <div class="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
      <div class="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
        <svg class="absolute top-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="74b3fd99-0a6f-4271-bef2-e80eeafdf357" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
        </svg>
        <svg class="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="f210dbf6-a58d-4871-961e-36d5016a0f49" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
        </svg>
        <svg class="absolute bottom-12 left-full transform translate-x-32" width="404" height="384" fill="none" viewBox="0 0 404 384">
          <defs>
            <pattern id="d3eb07ae-5182-43e6-857d-35c643af9034" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="384" fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
        </svg>
      </div>
    </div>
    <div class="relative px-4 sm:px-6 lg:px-8">
      <div class="text-lg max-w-prose mx-auto">
        <h1>
          <span class="block text-base text-center text-indigo-600 font-semibold tracking-wide uppercase">Polityka prywatności</span>
          <span class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">Aplikacja Geobox OBU</span>
        </h1>
        <p class="mt-8 text-xl text-gray-500 leading-8">Geobox Łukasz Szlagor (dalej jako Geobox) z siedzibą w Kluczborku jest producentem oprogramowania i aplikacji mobilnych. Szanujemy użytkowników naszych produktów i przykładamy bardzo dużą wagę do ochrony ich prywatności. Niniejszy dokument określa politykę prywatności i mechanizmy wykorzystania zasobów urządzenia przez aplikację mobilną Geobox OBU.</p>
      </div>
      <div class="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
        <p>Geobox nie zbiera żadnych danych osobowych, a jedynie dane dotyczące użytkowania aplikacji i błędów aplikacji. Dane te nie umożliwiają identyfikacji osoby.</p>
        <p>Dane zbierane automatycznie mogą być użyte do analizy zachowań użytkowników w aplikacji.</p>
        <p>Aplikacja Geobox OBU nie przechowuje żadnych danych osobowych, umożliwiających identyfikację konkretnego użytkownika aplikacji. Anonimowe dane mogą być przetwarzane w celach statystycznych.</p>
        <p>Uprawnienia aplikacji można odwołać przez zmianę ustawień systemowych.</p>
        <p>Korzystając w jakikolwiek sposób z aplikacji Geobox OBU, akceptujesz zasady zawarte w niniejszej Polityce Prywatności. Jeżeli nie zgadzasz się na Politykę Prywatności, odinstaluj aplikację Geobox OBU.</p>
        <p>W razie jakichkolwiek pytań lub sugestii, prosimy o kontakt: Geobox Łukasz Szlagor ul. Księdza Skargi 34, 46-203 Kluczbork, <a href="mailto:wsparcie@geoobax.app">wsparcie@geobox.app</a>.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: 'PolitykaApp'
}
</script>

<style scoped>

</style>