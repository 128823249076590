<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="relative bg-white overflow-hidden">

    <NawigacjaGlowna></NawigacjaGlowna>

    <main>

      <div class="bg-white">
        <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:flex lg:justify-between">
          <div class="max-w-xl">
            <h2 class="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">Skontaktuj się z nami</h2>
            <p class="mt-5 text-xl text-gray-500">Z przyjemnością odpowiemy na wszystkie pytania związane ze sprzedażą i obsługą klienta.</p>
          </div>
        </div>
      </div>

      <div class="bg-white">
        <div class="max-w-md mx-auto py-24 px-4 sm:max-w-3xl sm:py-32 sm:px-6 lg:max-w-7xl lg:px-8">
          <div class="divide-y divide-warm-gray-200">
            <section class="lg:grid lg:grid-cols-3 lg:gap-8" aria-labelledby="contactHeading">
              <h2 id="contactHeading" class="text-2xl font-extrabold text-warm-gray-900 sm:text-3xl">
                Dane kontaktowe
              </h2>
              <div class="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
                <div>
                  <h3 class="text-lg font-medium text-warm-gray-900">
                    Sprzedaż
                  </h3>
                  <dl class="mt-2 text-base text-warm-gray-500">
                    <div>
                      <dt class="sr-only">
                        Email
                      </dt>
                      <dd>
                        sprzedaz@geobox.app
                      </dd>
                    </div>
                    <div class="mt-1">
                      <dt class="sr-only">
                        Telefon
                      </dt>
                      <dd>
                        +48 575 516 666
                      </dd>
                      <dd>
                        +48 537 516 666
                      </dd>
                    </div>
                  </dl>
                </div>
                <div>
                  <h3 class="text-lg font-medium text-warm-gray-900">
                    Wsparcie techniczne
                  </h3>
                  <dl class="mt-2 text-base text-warm-gray-500">
                    <div>
                      <dt class="sr-only">
                        Email
                      </dt>
                      <dd>
                        wsparcie@geobox.app
                      </dd>
                    </div>
                    <div class="mt-1">
                      <dt class="sr-only">
                        Telefon
                      </dt>
                      <dd>
                        +48 537 516 666
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </section>
            <section class="mt-16 pt-16 lg:grid lg:grid-cols-3 lg:gap-8" aria-labelledby="locationHeading">
              <h2 id="locationHeading" class="text-2xl font-extrabold text-warm-gray-900 sm:text-3xl">
                Dane firmy
              </h2>
              <div class="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-12 lg:mt-0 lg:col-span-2">
                <div>
                  <h3 class="text-lg font-medium text-warm-gray-900">
                    Geobox Łukasz Szlagor
                  </h3>
                  <div class="mt-2 text-base text-warm-gray-500">
                    <p>
                      ul. Księdza Skargi 34
                    </p>
                    <p class="mt-1">
                      46-203 Kluczbork
                    </p>
                    <p class="mt-1">
                      NIP: 7511681387
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>

    </main>

  </div>

</template>

<script>

import NawigacjaGlowna from "@/components/NawigacjaGlowna";

export default {
  name: 'Kontakt',
  components: {
    NawigacjaGlowna
  },
  data: () => ({
    isOpen: false
  })
}
</script>
