<template>
  <div id="app">

    <router-view></router-view>

    <Stopka></Stopka>

  </div>
</template>

<script>

import Stopka from "@/components/Stopka";

export default {

  name: 'App',
  components: {
    Stopka
  }
}
</script>

