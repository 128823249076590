<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="relative bg-white overflow-hidden">

    <NawigacjaGlowna></NawigacjaGlowna>

    <main>

      <div class="bg-white">
        <div class="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8 lg:flex lg:justify-between">
          <div class="max-w-xl">
            <h2 class="text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">Geobox OBU</h2>
            <p class="mt-5 text-xl text-gray-500">To profesjonalne urządzenie telematyczne, spełniające wszystkie wymagania Krajowej Administracji Skarbowej. Niezawodność została potwierdzona pozytywnym wynikiem testów integracyjnych i przejazdowych.</p>
          </div>
        </div>
      </div>

      <div class="bg-white">
        <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
          <div>
            <h2 class="text-base font-semibold text-indigo-600 uppercase tracking-wide">Dowiedz się więcej</h2>
            <p class="mt-2 text-3xl font-extrabold text-gray-900">Geobox OBU</p>
            <p class="mt-4 text-lg text-gray-500">Poznaj najważniejsze cechy modułu. W przypadku pytań zapraszamy do kontaktu z <router-link to="/kontakt" class="inline-flex items-center font-medium text-indigo-600">działem technicznym</router-link>.</p>
          </div>
          <div class="mt-12 lg:mt-0 lg:col-span-2">
            <dl class="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-4 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
              <div class="relative">
                <dt>
                  <!-- Heroicon name: outline/check -->
                  <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Moduł GNSS</p>
                </dt>
                <dd class="mt-2 ml-9 text-base text-gray-500">
                  Moduł u-blox wykorzystuje do pozycjonowania trzy systemy pozycjonowania - GPS, Galileo oraz GLONASS. System wspomagania satelitarnego EGNOS zwiększa dokładność wyznaczania pozycji.
                </dd>
              </div>

              <div class="relative">
                <dt>
                  <!-- Heroicon name: outline/check -->
                  <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Certyfikaty</p>
                </dt>
                <dd class="mt-2 ml-9 text-base text-gray-500">
                  Moduły posiadają certyfikaty CE, e-Mark, FCC, EAC oraz RoHS. Produkcja urządzeń w Unii Europejskiej gwarantuje przestrzeganie wszystkich norm jakościowych i środowiskowych.
                </dd>
              </div>

              <div class="relative">
                <dt>
                  <!-- Heroicon name: outline/check -->
                  <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Akumulator</p>
                </dt>
                <dd class="mt-2 ml-9 text-base text-gray-500">
                  Wbudowany akumulator litowo-polimerowy zapewnia ciągłą pracę modułu w przypadku braku zasilania.
                </dd>
              </div>

              <div class="relative">
                <dt>
                  <!-- Heroicon name: outline/check -->
                  <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Aktualizacje</p>
                </dt>
                <dd class="mt-2 ml-9 text-base text-gray-500">
                  Zdalna aktualizacja oprogramowania i konfiguracji gwarantuje bezawaryjną pracę modułu.
                </dd>
              </div>

              <div class="relative">
                <dt>
                  <!-- Heroicon name: outline/check -->
                  <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Moduł GSM/GPRS</p>
                </dt>
                <dd class="mt-2 ml-9 text-base text-gray-500">
                  Czterozakresowy moduł GSM/GPRS. Rozszerzony zakres pracy w temperaturze od -40 °C do +85 °C. Przemysłowa karta telemetryczna SIM gwarantuje stabilną pracę w każdych warunkach.
                </dd>
              </div>

              <div class="relative">
                <dt>
                  <!-- Heroicon name: outline/check -->
                  <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Zasilanie</p>
                </dt>
                <dd class="mt-2 ml-9 text-base text-gray-500">
                  Szeroki zakres napięcia zasilania 6-31,5 VDC. Możliwość zasilania modułu z gniazda zapalniczki, złącza OBD lub dedykowanego złącza telematycznego.
                </dd>
              </div>

              <div class="relative">
                <dt>
                  <!-- Heroicon name: outline/check -->
                  <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Ochrona</p>
                </dt>
                <dd class="mt-2 ml-9 text-base text-gray-500">
                  Urządzenie posiada zabezpieczenie przed odwrotną polaryzacją zasilania oraz zabezpieczenie przeciwzwarciowe wbudowanego akumulatora.
                </dd>
              </div>

              <div class="relative">
                <dt>
                  <!-- Heroicon name: outline/check -->
                  <svg class="absolute h-6 w-6 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
                  </svg>
                  <p class="ml-9 text-lg leading-6 font-medium text-gray-900">Wymiary</p>
                </dt>
                <dd class="mt-2 ml-9 text-base text-gray-500">
                  Kompaktowa obudowa umożliwia prosty montaż w każdym pojeździe.
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>

    </main>

  </div>

</template>

<script>

import NawigacjaGlowna from "@/components/NawigacjaGlowna";

export default {
  name: 'Kontakt',
  components: {
    NawigacjaGlowna
  },
  data: () => ({
    isOpen: false
  })
}
</script>
