<template>

  <footer class="bg-white">
    <div class="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
      <nav class="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Stopka">
        <div class="px-5 py-2">
          <router-link to="/" class="text-base text-gray-500 hover:text-gray-900">
            Strona główna
          </router-link>
        </div>

        <div class="px-5 py-2">
          <router-link to="/produkty" class="text-base text-gray-500 hover:text-gray-900">
            Produkty
          </router-link>
        </div>

        <div class="px-5 py-2">
          <router-link to="/oferta" class="text-base text-gray-500 hover:text-gray-900">
            Oferta
          </router-link>
        </div>

        <div class="px-5 py-2">
          <router-link to="/polityka" class="text-base text-gray-500 hover:text-gray-900">
            Polityka
          </router-link>
        </div>

        <div class="px-5 py-2">
          <router-link to="/kontakt" class="text-base text-gray-500 hover:text-gray-900">
            Kontakt
          </router-link>
        </div>

      </nav>
      <p class="mt-8 text-center text-base text-gray-400">
        &copy; 2021 Geobox
      </p>
    </div>
  </footer>

</template>

<script>

export default {
  name: 'Stopka'
}
</script>
