import { createApp }  from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

import './index.css'

import App from './App.vue'
import StronaGlowna from "@/components/StronaGlowna";
import Produkty from "@/components/Produkty";
import Oferta from "@/components/Oferta";
import Kampania from "@/components/Kampania";
import Kontakt from "@/components/Kontakt";
import PolitykaApp from "@/components/PolitykaApp";

const routes = [
    { path: '/', component: StronaGlowna },
    { path: '/produkty', component: Produkty },
    { path: '/oferta', component: Oferta },
    { path: '/kampania', component: Kampania },
    { path: '/kontakt', component: Kontakt },
    { path: '/polityka', component: PolitykaApp },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

const app = createApp(App);
app.use(router)
app.mount('#app')
